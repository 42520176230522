import 'bootstrap';

import '../PrettyDocs/plugins/elegant_font/css/style.css'

import '../scss/app.scss';

import 'tocbot';
import 'tocbot/dist/tocbot.css';

import 'opensans-npm-webfont';
import "../code_styles.css";

// import '@fortawesome/fontawesome-free/css/all.css';
// import '@fortawesome/fontawesome-free/js/all.js';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';

// import Prism from 'prismjs';
// Prism.highlightAll();
